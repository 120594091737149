.file-preview-save {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  background-color: #b3d8be;
  position: relative;
  cursor: pointer;
}

.file-preview-save p, .file-preview-not-save p {
  margin: 0;
  margin-top: 20px;
  font-size: 14px;
  word-break: break-all;
}

.file-preview-save button, .file-preview-not-save button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  /* padding: 2px 5px; */
}

.file-preview-save:hover {
  background-color: #bde0c8;
  border-color: #ccc;
}
.file-preview-not-save:hover {
  background-color: #e9ecef;
  border-color: #ccc;
}

.file-preview-not-save {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  position: relative;
}

/* FileUploadCard.css */
.file-upload-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.file-upload-container:hover {
  background-color: #e9ecef;
  border-color: #ccc;
}

.file-upload-container input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-upload-text {
  font-size: 14px;
  color: #555;
}


.profile-image{
  width: 250px;
  border-radius: 100%;
}
