.tableRowCenter{
  white-space: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.tableRowStart{
  white-space: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.tableRowEnd{
  white-space: nowrap;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: right;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.trBody:hover{
  background-color:#ececec;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; 
}

.tableBorder {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;  
}

th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.tableReceber {
  height: 60vh !important;
  overflow-y: auto;   
} 

@media (max-width: 1366px) {
  .tableReceber {
    height:  50vh !important; 
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .tableReceber {
    height:  77vh !important; 
    overflow-y: auto;
  }
}

@media (max-width: 400px) {
  .tableReceber {
    height:  72vh !important; 
    overflow-y: auto;
  }
}

@media (max-width: 360px) {
  .tableReceber {
    height:  72vh !important; 
    overflow-y: auto;
  }
}