.custom-calendar {
  position: relative;
  z-index: 1;
}

.custom-calendar .rbc-calendar {
  z-index: 1;
}

.rbc-toolbar-label{
  font-weight: bold;
  text-transform: capitalize;
}

.rbc-event-content{
  font-size: 12px;
}

.rbc-time-content  > :nth-child(6){
  border-right:2px solid red ;
}
.rbc-time-content  > :nth-child(11){
  border-right:2px solid red ;
}
.rbc-time-content  > :nth-child(16){
  border-right:2px solid red ;
}
.rbc-time-content  > :nth-child(21){
  border-right:2px solid red ;
}
.rbc-time-content  > :nth-child(26){
  border-right:2px solid red ;
}
.rbc-time-content  > :nth-child(31){
  border-right:2px solid red ;
}
.rbc-time-content  > :nth-child(36){
  border-right:2px solid red ;
}

.container-legenda {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.item-legenda {
  padding: 5px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-legenda {
  margin: 0;
  font-weight: bold;
  font-size: 10px;
}



