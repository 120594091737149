.webcam{
  width: 40%;
}

.foto{
  width: 100%;
  height: 'auto';
  margin-right: 10px 
}
.div-foto{
  width: 40%;
}

.div-foto p{
  margin: 0;
  padding: 0;
  text-align: center;
}

@media(max-width:767px){
  .webcam{
    width: 100%;
  }
  .div-foto{
    width: 100%;
  }
  .foto{
    margin-top: 20px;
    width: 100%;
  }
}