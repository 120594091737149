@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* font-family: Inter, sans-serif; */
}

.text-menu{
  color: white
}

.cardShadow{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none !important;
}

.textPrimary{
  /* color: #627183; */
  /* color: #00162f; */
  color: #2d3635;
}

.pagination_container {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.pagination_container li {
  margin: 0 5px;
  display: inline-block;
}

.pagination_container a {
  text-decoration: none;
  padding: 4px 8px;
  border: 1px solid #2d3635;
  color: #2d3635;
  border-radius: 5px;
  cursor: pointer;
}

.pagination_container a:hover {
  background-color: #2d3635;
  color: #fff !important;
}

.pagination_container .active a,
.pagination_container .active a:hover {
  background-color: #2d3635 !important;
  color: #fff !important;
}

