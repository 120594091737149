/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.custom-shape-divider-top-1713470869 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 2
}

.custom-shape-divider-top-1713470869 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 123px;
}

.custom-shape-divider-top-1713470869 .shape-fill {
  fill: #FFFFFF;
}

/* .sign-in__backdrop0 {
  position: absolute;
  z-index: 0;
  inset: 0;
  background-color: #aebef3;
} */
.sign-in__backdrop1 {
  position: absolute;
  /* z-index: 0; */
  inset: 0;
  background-color: #277076;
  /* --mask: 
  radial-gradient(30px at 25% 0   ,#0000 98%,#000) 50% 30px/120px 51% repeat-x,
  radial-gradient(30px at 75% 50% ,#000 99%,#0000 101%) top/120px 60px repeat-x,
  radial-gradient(30px at 75% 100%,#0000 98%,#000) calc(50% + 60px) calc(100% - 30px)/120px 51% repeat-x,
  radial-gradient(30px at 25% 50% ,#000 99%,#0000 101%) calc(50% + 60px) 100%/120px 60px repeat-x;
-webkit-mask: var(--mask);
        mask: var(--mask); */
}
/* .sign-in__backdrop2 {
  position: absolute;
  z-index: 1;
  inset: 0;
  background-image: url('../../assets/lm.png');
  background-repeat: space;
  background-attachment: fixed;
  background-position: center top;
  background-size: contain;
  opacity: 0.2;
} */

.sign-in__backdrop3{
  .box {
    width: 200px;
    aspect-ratio: 1;
    clip-path: polygon(100% 50%,97.55% 65.45%,90.45% 79.39%,79.39% 90.45%,65.45% 97.55%,50% 100%,34.55% 97.55%,20.61% 90.45%,9.55% 79.39%,2.45% 65.45%,0% 50%,2.45% 34.55%,9.55% 20.61%,20.61% 9.55%,34.55% 2.45%,50% 0%,65.45% 2.45%,79.39% 9.55%,90.45% 20.61%,97.55% 34.55%);
    color: red;
    z-index: 2;
    position: absolute;
    top:0
  }
}

.logo_lm_fundo{
  z-index: 2;
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 3;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 4rem;
}

@keyframes showSignInForm {
  0%,
  30% {
    transform: translate(0, -150%);
  }
  70%,
  90% {
    transform: translate(0, 1rem);
  }
  80%,
  100% {
    transform: translate(0, 0);
  }
}

